jQuery(document).ready(function() {
	// initialize tabs with default behaviors
	jQuery('#example1').tabs();
	jQuery('#example2').tabs({ cache: true});
	jQuery('#example3').tabs({ cache: true });
	jQuery('#example4').tabs({ cache: true, selectOnAdd: true });
	jQuery('#example4').tabs('select', 21);
	jQuery('#productLineTabs').tabs({ cache: true, cookie: {expires: 1 } });

	// initialize paging
	function init() {
		jQuery('#example1').tabs('paging');
		jQuery('#example2').tabs('paging', { cycle: true });
                jQuery('#productLineTabs').tabs('paging', { cycle: true });
		jQuery('#example3').tabs('paging', { follow: true, followOnSelect: true, tabsPerPage: 5 });
		jQuery('#example4').tabs('paging', { cycle: true, follow: true, followOnSelect: true, selectOnAdd: true });
	}
	init();
        jQuery(".ui-tabs-paging-next").find("a").html('<div id="next_button"></div>');
        jQuery(".ui-tabs-paging-prev").find("a").html('<div id="prev_button"></div>');
	// configure themeswitcher
	/*
	$('#switcher').themeswitcher({
		onSelect: function() {
			// reinit paging whenever new theme is selected
			jQuery('#example1').tabs('pagingDestroy');
			jQuery('#example2').tabs('pagingDestroy');
			jQuery('#example3').tabs('pagingDestroy');
			jQuery('#example4').tabs('pagingDestroy');		
			setTimeout(init, 500);
		}
	});
	*/
});